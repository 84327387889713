var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "PurchaseSettlement" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "买料结算",
          "left-arrow": "",
          fixed: "",
          "safe-area-inset-top": "",
          placeholder: ""
        },
        on: { "click-left": _vm.leftClick }
      }),
      _c("div", { staticClass: "content" }, [
        _vm.BuyOrderList.bankInfo
          ? _c(
              "div",
              { staticClass: "ctnOne" },
              [
                _c("div", { staticClass: "zxjAccountNumber" }, [
                  _c("h3", [_vm._v(_vm._s(_vm.GLOBAL) + "指定收款账号")]),
                  _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.BuyOrderList.payStatus == "WAIT"
                            ? "待付款"
                            : _vm.BuyOrderList.payStatus == "PAYED"
                            ? "已付款"
                            : "待确认"
                        )
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "flex margin" }, [
                  _c("div", { staticClass: "account flexLeftColor" }, [
                    _vm._v("收款账户：")
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "accountNumber flexRightColor",
                      on: {
                        click: function($event) {
                          return _vm.copy(_vm.BuyOrderList.bankInfo.num)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.BuyOrderList.bankInfo.num))]
                  )
                ]),
                _c("div", { staticClass: "flex margin" }, [
                  _c("div", { staticClass: "account flexLeftColor" }, [
                    _vm._v("收款银行:")
                  ]),
                  _c("div", { staticClass: "accountNumber flexRightColor" }, [
                    _vm._v(_vm._s(_vm.BuyOrderList.bankInfo.bank))
                  ])
                ]),
                _c("div", { staticClass: "flex margin" }, [
                  _c("div", { staticClass: "account flexLeftColor" }, [
                    _vm._v("收款人:")
                  ]),
                  _c("div", { staticClass: "accountNumber flexRightColor" }, [
                    _vm._v(_vm._s(_vm.BuyOrderList.bankInfo.name))
                  ])
                ]),
                _c("div", { staticClass: "flex margin" }, [
                  _c("div", { staticClass: "account flexLeftColor" }, [
                    _vm._v("开户支行:")
                  ]),
                  _c("div", { staticClass: "accountNumber flexRightColor" }, [
                    _vm._v(_vm._s(_vm.BuyOrderList.bankInfo.address))
                  ])
                ]),
                _c("div", { staticClass: "flex margin jietu" }, [
                  _c(
                    "h3",
                    { staticStyle: { color: "'#666666'" } },
                    [
                      _vm._v("上传付款凭证截图 "),
                      _c("van-icon", { attrs: { name: "arrow" } })
                    ],
                    1
                  )
                ]),
                _c(
                  "van-uploader",
                  {
                    attrs: {
                      multiple: "",
                      "max-count": 3,
                      "after-read": _vm.afterRead,
                      "before-delete": _vm.beforeDelete
                    },
                    model: {
                      value: _vm.fileList,
                      callback: function($$v) {
                        _vm.fileList = $$v
                      },
                      expression: "fileList"
                    }
                  },
                  [
                    _c("div", { staticClass: "upload" }, [
                      _c("div", { staticClass: "shu" }),
                      _c("div", { staticClass: "heng" })
                    ])
                  ]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.BuyOrderList.itemList
          ? _c(
              "div",
              { staticClass: "ctnTwo" },
              [
                _c("h3", { staticClass: "ctnTwoH3" }, [_vm._v("结算信息")]),
                _c("div", { staticClass: "flex margin" }, [
                  _c("div", { staticClass: "account flexLeftColor" }, [
                    _vm._v("结算时间:")
                  ]),
                  _c("div", { staticClass: "accountNumber flexRightColor" }, [
                    _vm._v(
                      _vm._s(_vm._f("formatDate_")(_vm.BuyOrderList.created))
                    )
                  ])
                ]),
                _c("div", { staticClass: "flex margin" }, [
                  _c("div", { staticClass: "account flexLeftColor" }, [
                    _vm._v("结算单号:")
                  ]),
                  _c("div", { staticClass: "accountNumber flexRightColor" }, [
                    _vm._v(_vm._s(_vm.BuyOrderList.openNo))
                  ])
                ]),
                _c("div", { staticClass: "flex margin orderContent" }, [
                  _c("div", { staticClass: "account flexLeftColor" }, [
                    _vm._v("订单数量:")
                  ]),
                  _c("div", { staticClass: "accountNumber flexRightColor" }, [
                    _vm._v(_vm._s(_vm.BuyOrderList.itemList.length))
                  ])
                ]),
                _vm._l(_vm.BuyOrderList.itemList, function(item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "jieSuanOrder" },
                    [
                      _c("div", { staticClass: "flex margin jieSuanTime" }, [
                        _c("div", { staticClass: "account flexLeftColor" }, [
                          _vm._v("订单编号:")
                        ]),
                        item.orderId
                          ? _c(
                              "div",
                              {
                                staticClass: "accountNumber flexRightColor",
                                on: {
                                  click: function($event) {
                                    return _vm.openOrderIdentifier(item.orderId)
                                  }
                                }
                              },
                              [
                                _vm._v(_vm._s(item.orderId) + " "),
                                _c("van-icon", { attrs: { name: "arrow" } })
                              ],
                              1
                            )
                          : _vm._e(),
                        !item.orderId
                          ? _c(
                              "div",
                              { staticClass: "accountNumber flexRightColor" },
                              [_vm._v("现价结算")]
                            )
                          : _vm._e()
                      ]),
                      _c("div", { staticClass: "flex margin" }, [
                        _c("div", { staticClass: "account flexLeftColor" }, [
                          _vm._v("重量(克):")
                        ]),
                        _c(
                          "div",
                          { staticClass: "accountNumber flexRightColor" },
                          [_vm._v(_vm._s(item.weight.toFixed(1)))]
                        )
                      ]),
                      _c("div", { staticClass: "flex margin" }, [
                        _c("div", { staticClass: "account flexLeftColor" }, [
                          _vm._v("单价(元/克):")
                        ]),
                        _c(
                          "div",
                          { staticClass: "accountNumber flexRightColor" },
                          [_vm._v(_vm._s(item.price))]
                        )
                      ]),
                      _c("div", { staticClass: "flex margin" }, [
                        _c("div", { staticClass: "account flexLeftColor" }, [
                          _vm._v("保管费:")
                        ]),
                        _c(
                          "div",
                          { staticClass: "accountNumber flexRightColor" },
                          [
                            _vm._v(
                              _vm._s(item.storageFee ? item.storageFee : 0)
                            )
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "flex margin orderContent" }, [
                        _c("div", { staticClass: "account flexLeftColor" }, [
                          _vm._v("金额(元):")
                        ]),
                        _c(
                          "div",
                          { staticClass: "accountNumber flexRightColor" },
                          [
                            _vm._v(
                              _vm._s(
                                item.storageFee
                                  ? item.money + item.storageFee
                                  : item.money
                              )
                            )
                          ]
                        )
                      ])
                    ]
                  )
                }),
                _c("div", { staticClass: "flex margin total" }, [
                  _c("h3", { staticClass: "totalWeight" }, [
                    _vm._v("合计重量(克) "),
                    _c("span", [_vm._v(_vm._s(_vm.totalWeight.toFixed(1)))])
                  ]),
                  _c("h3", { staticClass: "totalMoney" }, [
                    _vm._v("合计金额(元) "),
                    _c("span", [_vm._v(_vm._s(_vm.totalMoney))])
                  ])
                ])
              ],
              2
            )
          : _vm._e(),
        _vm.BuyOrderList.fetchInfo
          ? _c("div", { staticClass: "ctnThree" }, [
              _c("div", { staticClass: "flex margin quHuoInfo" }, [
                _c("h3", [_vm._v("取货信息")]),
                _c(
                  "div",
                  { staticClass: "accountNumber flexRightColor isFetched" },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.BuyOrderList.fetchInfo.fetched ? "已取货" : "未取货"
                      )
                    )
                  ]
                )
              ]),
              _c("div", { staticClass: "flex margin" }, [
                _c("div", { staticClass: "account flexLeftColor" }, [
                  _vm._v("取货方式:")
                ]),
                _c("div", { staticClass: "accountNumber flexRightColor" }, [
                  _vm._v(
                    _vm._s(
                      _vm.BuyOrderList.fetchInfo.type == "PLACE"
                        ? "自取"
                        : "快递"
                    )
                  )
                ])
              ]),
              _c("div", { staticClass: "flex margin" }, [
                _c("div", { staticClass: "account flexLeftColor" }, [
                  _vm._v("地址:")
                ]),
                _c("div", { staticClass: "accountNumber flexRightColor" }, [
                  _vm._v(_vm._s(_vm.BuyOrderList.fetchInfo.address.address))
                ])
              ]),
              _c("div", { staticClass: "flex margin" }, [
                _c("div", { staticClass: "account flexLeftColor" }, [
                  _vm._v("取货码:")
                ]),
                _c("div", { staticClass: "accountNumber flexRightColor" }, [
                  _vm._v(
                    _vm._s(
                      _vm.BuyOrderList.fetchInfo.fetchCode
                        ? _vm.BuyOrderList.fetchInfo.fetchCode
                        : "--"
                    )
                  )
                ])
              ]),
              _c("div", { staticClass: "flex margin" }, [
                _c("div", { staticClass: "account flexLeftColor" }, [
                  _vm._v("联系电话:")
                ]),
                _c("div", { staticClass: "accountNumber flexRightColor" }, [
                  _vm._v(_vm._s(_vm.BuyOrderList.fetchInfo.address.phone))
                ])
              ]),
              _c("div", { staticClass: "flex margin" }, [
                _c("div", { staticClass: "account flexLeftColor" }, [
                  _vm._v("备注:")
                ]),
                _c("div", { staticClass: "accountNumber flexRightColor" }, [
                  _vm._v(_vm._s(_vm.BuyOrderList.fetchInfo.remark))
                ])
              ]),
              _vm._m(0)
            ])
          : _vm._e()
      ]),
      _c("footer", [
        _vm._v(
          "说明:由于货款入账审核需要一定时间,如果您已付货款,只需耐心等待审核人员审核即可,任何疑问可直接拨打客服热线:400-850-6718"
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex margin" }, [
      _c("div", { staticClass: "account flexLeftColor" }, [
        _vm._v("料部电话:")
      ]),
      _c("div", { staticClass: "accountNumber flexRightColor" }, [
        _vm._v("400-850-6718")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }